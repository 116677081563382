import React,{ useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import AsyncSelect from 'react-select/async';

import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DateFnsUtils from '@date-io/date-fns';





import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';




import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';


import {currentRouteSet} from '../../actions/actions';

import {pathSpliter,checkAuthBranchWare,dateTimeFormat,currentDateTime,dateFormat} from '../../lib/functions'
import moment from 'moment';


import _ from 'lodash';
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')

const GroupManage = ({location,currentRouteSet,currentRoute,authInfo})=>{
    const classes = useStyles();
 
  useEffect(()=>{
    getContras();
    currentRouteSet(pathSpliter(location.pathname,1));
    getContraCode();
    getToBranches()
  },[])

    // Init states start
 
    
    let [tran_id,tran_id_set] = useState(0)
    let [trans,contrasSet] = useState([])
    let [toBranches,toBranchesSet] = useState([]);

    let [loadFromAccounts,loadFromAccountsSet] = useState([])
    let [loadToAccounts,loadToAccountsSet] = useState([])
    
    let [loadingList,loadingListSet] = useState(false)
    let [loadingSave,loadingSaveSet] = useState(false)

    let [action,action_set] = useState('create');
    let [tran_code,tran_code_set] = useState('');
    let [tran_ind_id,tran_ind_id_set] = useState(null);

    let [narration,narration_set] = useState('');

    

    let [tran_amount,tran_amount_set] = useState('');
    
    
    const [creationDate, creationDateSet] = useState(currentDateTime);
    const [selectedAccountFrom, selectedAccountFromSet] = useState(null);
    const [selectedAccountTo, selectedAccountToSet] = useState(null);
    const [selectedToBranch, selectedToBranchSet] = useState(null);

    let code_ref = useRef(null)
    let acc_from_ref = useRef(null)
    let acc_to_ref = useRef(null)
    let tran_amount_ref = useRef(null)
    let creation_date_ref = useRef(null)
    let narration_ref = useRef(null)


  
  useEffect(()=>{
    getContras()
  },[creationDate])

   
    const creationDateChange = (date) => {
        creationDateSet(date);
      };


    
    const getContras = async ()=>{
        await axios.post(`${API_URL}/api/get-branch-trans`,{oneDate:creationDate},{headers:{'auth-token':authInfo.token}}).then(res=>{
        contrasSet(res.data)
      })
    }


    
    const [account_name_from, account_name_from_set] = useState("");
    const [account_name_to, account_name_to_set] = useState("");

    useEffect(()=>{
        getLoadFromAccounts()
      },[account_name_from])
  
    
    useEffect(()=>{

        if(action != 'update'){
                    selectedAccountToSet(null)

        }
    
    loadToAccountsSet([])
      getLoadToAccounts()

    },[selectedToBranch])




    const getToBranches = async() => {
        return  await axios.post(`${API_URL}/api/get-branches`,{"without-self":'yes'},{headers:{'auth-token':authInfo.token}}).then(res=>{
          toBranchesSet(res.data.message)
          })
      };


    const getLoadFromAccounts = async() => {
      return  await axios.post(`${API_URL}/api/get-accounts-by-search`,{query:account_name_from,search:true,multiType:['cash_in_hand','bank_account']},{headers:{'auth-token':authInfo.token}}).then(res=>{
        loadFromAccountsSet(res.data)
        })
    };

    const getLoadToAccounts = async() => {
        if(selectedToBranch == null){
          return false
        }
      return  await axios.post(`${API_URL}/api/get-branch-accs`,{
        toBranchId: selectedToBranch.branch_id ,
        multiType:['cash_in_hand','bank_account']},{headers:{'auth-token':authInfo.token}}).then(res=>{
        loadToAccountsSet(res.data)
        })
    };

    const getContraCode = async() => {
      return  await axios.post(`${API_URL}/api/get-branch-tran-code`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
          tran_code_set(res.data)
        })
    };
  
  



    // Init states end
    // Methods script start
    const saveFormAction = async ()=>{


            if(selectedToBranch == null){
                swal({
                  title:`Select To Branch`,
                  icon:'warning'
                });
                return false
              }else if(selectedAccountTo == null){
                swal({
                  title:`Select  To  Account`,
                  icon:'warning'
                });
                return false
              }else if(selectedAccountFrom == null){
                swal({
                  title:`Select From Account`,
                  icon:'warning'
                });
                return false
              }else if(tran_amount == ''){
                swal({
                  title:`Amount is Required.`,
                  icon:'warning'
                });
                return false
              }else{
            let payLoad = {
             
                tran_code:tran_code.trim(), 
                tran_date:creationDate,
                to_branch_id : selectedToBranch.branch_id,
                from_acc_id: selectedAccountFrom.acc_id,
                to_acc_id: selectedAccountTo.acc_id,
                tran_amount: tran_amount == ''?0:tran_amount,
                narration,
                tran_id,
                action
              
              
            }
            loadingSaveSet(true);
            await axios.post(`${API_URL}/api/save-branch-tran`,payLoad,{headers:{'auth-token': authInfo.token}}).then(res=>{
              loadingSaveSet(false);
             if(res.data.error == false){
               swal({
                 title: res.data.msg,
                 icon:'success'
               })
               creationDateSet(currentDateTime)
               tran_amount_set('')
               action_set('create')
               narration_set('')
               tran_id_set(0)
               getContraCode()
               getContras()
               selectedAccountFromSet(null)
               selectedAccountToSet(null)
               selectedToBranchSet(null)

             }else{
              swal({
                title: res.data.msg,
                icon:'warning'
              })
             }
            });

            }
         
            
          
          
    }




    // Methods script end
    const ActionOptions = (props)=>{
      return(<div style={{textAlign:'right'}}> 

{
    authInfo.role !='user'?(
    <>
        <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>contraEdit(props.rowData)}/>
      
        <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>contraDelete(props.rowData)}/>
            
   </>):''
 }
      
      </div>)
    
    }


    const contraEdit = (row,index)=>{
      let contra =  trans.filter((contra)=>contra.tran_id == row[0])
      contra = contra[0]

      selectedToBranchSet({branch_name:contra.branch_name,branch_id:contra.to_branch_id})


      selectedAccountFromSet({acc_name:contra.from_acc_name,acc_id:contra.from_acc_id})
      selectedAccountToSet({acc_name:contra.to_acc_name,acc_id:contra.to_acc_id}) 

      
      tran_id_set(contra.tran_id)
      action_set('update')
      tran_amount_set(contra.tran_amount)
      tran_code_set(contra.tran_code)
      creationDateSet(contra.creation_date)
      narration_set(contra.narration)
     
    }


    const editContraTran = (row,sl)=>{
      tran_ind_id_set(sl)
    //   selectedAccountFromSet({acc_name:row.acc_name,acc_id:row.acc_id})
      tran_amount_set(row.tran_amount)
    }


    const contraDelete = async (row)=>{
      swal({
        title:'Are you sure delete this?',
        icon:'warning',
        buttons:true
      }).then(async(yes)=>{
        if(yes){
          let contra =  trans.filter((contra)=>contra.tran_id == row[0])
          await axios.post(`${API_URL}/api/delete-branch-tran`,{tran_id:contra[0].tran_id},
          {headers:{'auth-token':authInfo.token}}).then(res=>{
                  getContras();
                  swal({
                    title: res.data.msg,
                    icon:'success'
                  })
                  
                })
        }else{
          return false
        }
      })
      

          }
   
    const columns = [
      {name: "tran_id",options: { display: 'excluded' }},
      {name: "status",options: { display: 'excluded' }},
      {name:"SL",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
        return ( <p>{parseFloat(tableMeta.rowIndex)+1}</p> ); 
      }
      },headerStyle: {
        textAlign:'left'
      }},
      {name: "tran_code",label: " Tran Code",options: {filter: true,sort: true}},
      {name: "creation_date",label: " Tran Date",options: {filter: true,sort: true,
        customBodyRender:(value,tableMeta)=>{
          return(<p>{ moment(tableMeta.rowData[4]).format(dateFormat) }</p>) 
        }
      }},
      {name: "branch_name",label: "To Branch",options: {filter: true,sort: true}},
      {name: "from_acc_name",label: "From Account",options: {filter: true,sort: true}},
      {name: "to_acc_name",label: "To Branch Account",options: {filter: true,sort: true}},
      {name: "tran_amount",label: " Amount",options: {filter: true,sort: true}},
      {name: "narration",label: "Narration",options: {filter: true,sort: true}},
      {name: "user_name",label: "Created By",options: {filter: true,sort: true}},


      {name: "status",label: "Status",options: {filter: true,sort: true,
        customBodyRender:(value,tableMeta)=>{
          return(<p>{ tableMeta.rowData[1] == 'a' ? 'Active' : 'Pending' }</p>) 
        }
      }},


      {name:"actions",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
          return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
             /> ); 
      }
      },headerStyle: {
        textAlign:'right'
      }}
     ];
            
     const options = {
       filterType: 'checkbox',
       selectableRows: 'none',
       display: "excluded"
      }


    return (
    <div className={classes.root}>

     <Paper className={classes.paper} style={{marginTop:'-15px'}}>
    <h2 className={classes.pageEntryLabel}>Branch Transaction </h2>
      <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
 


     


<Grid item xs={12} sm={12}>


<Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={toBranches} 
              size="small"
              classes={{
                option: classes.option,
              }}
              
              getOptionLabel={(option) => option.branch_name}
              value={selectedToBranch}
              onChange={(e,selectedObj)=>{
                selectedToBranchSet(selectedObj)
               
              }}
              renderInput={(params) => (
                <TextField
                onKeyDown={(e)=>{
                    if (e.key === "Enter") {
                        acc_to_ref.current.focus()
                     }
                }}
                 
                  {...params}
                  label="To Branch"
                  variant="outlined"
                
                />
              )}
          />




<Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%',marginTop:'7px' }}

              options={loadFromAccounts} 
              size="small"
              classes={{
                option: classes.option,
              }}
              
              getOptionLabel={(option) => option.acc_name}
              value={selectedAccountFrom}
              onChange={(e,selectedObj)=>{
                selectedAccountFromSet(selectedObj)
                if (e.key === "Enter") {
                  tran_amount_ref.current.focus()
                 }
              }}
              renderInput={(params) => (
                <TextField
                  inputRef={acc_from_ref}
                  onChange={(e)=>{
                    account_name_from_set(e.target.value);
                   
                  }}
                  {...params}
                  label="From Account"
                  variant="outlined"
                
                />
              )}
          />


<Grid item xs={12} sm={12} style={{marginTop:'15px'}}> 
        <TextField type="number"  
         inputRef={tran_amount_ref}

         onKeyDown={event => {
            if (event.key === "Enter") {
             narration_ref.current.focus()
            }
          }}


          autoComplete='off' className={classes.fullWidth} 
        label="Transaction Amount" name="tran_amount" value={tran_amount} 
        variant="outlined" size="small" onChange={(e)=>{

                    tran_amount_set(e.target.value)
                
           
            }} />


        </Grid>

</Grid>




        


     

      </Grid>


      <Grid item xs={12} sm={4}>


      <Grid item xs={12} sm={12} style={{float:'left',width: '100%'}}>
     
     
            <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={loadToAccounts} 
              size="small"
              classes={{
                option: classes.option,
              }}
              
              getOptionLabel={(option) => option.acc_name}
              value={selectedAccountTo}
              onChange={(e,selectedObj)=>{
                selectedAccountToSet(selectedObj)
                if (e.key === "Enter") {
                  acc_from_ref.current.focus()
                 }
              }}
              renderInput={(params) => (
                <TextField
                  inputRef={acc_to_ref}
                  onKeyDown={(e)=>{
                    if(e.key == 'Enter'){
                        acc_from_ref.current.focus()

                    }

                  }}
                
                  {...params}
                  label="To Branch Account"
                  variant="outlined"
                
                />
              )}
          />


            </Grid>

     

           



      <Grid item xs={12} sm={12}>
      <TableContainer  style={{width:'100%'}} >
      <Table className={classes.table} size="small" aria-label="a dense table">
     
          <TableRow > 
             <TableCell style={{width: '325px'}}><TextareaAutosize  
             ref={narration_ref}
            
               
               onChange={(e)=>{narration_set(e.target.value)}} value={narration}  name="narration" style={{float:'left',marginTop:'20px',width: '325px'}} aria-label="Narration..." rowsMin={3} placeholder="Narration..." />
             </TableCell>

             <TableCell colSpan={2}></TableCell>

           
          
          </TableRow>
        
      </Table>
    </TableContainer>
      </Grid>
     





      </Grid>

      <Grid item xs={12} sm={4}>


      <Grid item xs={12} sm={12} style={{marginTop:'0px'}}>
      <TextField  autoComplete='off' className={classes.fullWidth} 
        inputRef={code_ref}
        onKeyDown={event => {
            if (event.key === "Enter") {
              creation_date_ref.current.focus()
            }
          }} 
          
        label="Branch Tran Code" name="tran_code" value={tran_code} 
        variant="outlined" size="small" onChange={(e)=>tran_code_set(e.target.value)} />
      </Grid>



      <Grid item xs={12} sm={12} style={{width:'100%',marginTop: '25px'}}>


      <LocalizationProvider dateAdapter={AdapterDateFns} style={{marginTop: '25px'}}>
        <Stack spacing={3}>
          <DesktopDatePicker
           readOnly={authInfo.role == 'user' ? true : false}
            label="Branch Tran Date" 
            inputFormat={dateTimeFormat}
            inputRef={creation_date_ref}
           
            value={creationDate}
            onChange={(e)=>creationDateChange(e)}
            renderInput={(params) => <TextField 
              onKeyDown={event => {
                if (event.key === "Enter") {
                  saveFormAction()
                }
            }}
            
              {...params} />}
          />
          
        </Stack>
        </LocalizationProvider>





      </Grid>


    
      </Grid>


      </Grid>


  
         <Grid item xs={12}>
  <Button style={{marginTop: '25px'}}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        style={{color: 'white',
          backgroundColor: '#115f5f'}}
        disabled={loadingSave}
        startIcon={<SaveIcon />}
        onClick={saveFormAction}
      >
        Save
      </Button>
  </Grid>         


     </Paper>

     {
      loadingList==true?(<b>Loading...</b>):(
        <Paper className={classes.paper} style={{marginTop:'20px'}}>
        <MUIDataTable
      title={"Branch Transaction List"}
      data={trans}
      columns={columns}
      options={options}
      adjustForCheckbox={false} 
      displaySelectAll={false}
      />
      </Paper>
      )
      
     }

     
    </div>
        
    );
}


 const options = {
   filterType: 'checkbox',
 };
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },

root: {},
'& .MuiTextField-root': {
  margin: theme.spacing(1),
  width: '25ch',
 },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  },
  pageEntryLabel:{
      color: '#7754cc',
      margin: '0px',
      padding: '0px',
      marginTop: '-11px',
      textAlign: 'left',
      marginLeft: '0px',
      marginBottom: '5px'
  },
  fullWidth:{
      width:'100%'
  },
  option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    plusLinkDiv:{
        position:'relative'  
  },
  plusLink:{
    margin: 0,
    padding: 0,
    marginTop: '-21px',
    fontSize: '29px',
    height: '21px',
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    color: '#3e8d54'
  }
}));

const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(GroupManage);