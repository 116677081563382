import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux';

import {currentRouteSet} from '../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'; 
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import {pathSpliter,_p,sleep,dateTimeFormat,dateFormat,currentDateTime,getDateTimeFromISODT,currentDateStartTime,currentDateEndTime} from '../../lib/functions'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DdatateIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DateFnsUtils from '@date-io/date-fns'; // choose your libs
import {API_URL} from '../../config.json';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import _ from 'lodash';
import ReactToPrint from "react-to-print";
import PrintIcon from '@material-ui/icons/Print';
import InstitutionProfile from '../commons/institution_profile'
import '../commons/commons.css'

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';


import swal from 'sweetalert';
import commaNumber from 'comma-number';
import { TableFooter } from 'semantic-ui-react';
let format = commaNumber.bindWith(',', '.')
const SalesRecord = ({location,currentRoute,currentRouteSet,authInfo})=>{
    const classes = useStyles();
    useEffect(()=>{
        currentRouteSet(pathSpliter(location.pathname,1));
        getLocations()
    },[]);

    let [types,typesSet] = useState([{type:'All Debitor'},{type:'Debitor Wise'},{type:'Location Wise'}]);
    let [report,reportSet] = useState([]);
    let [selectedType,selectedTypeSet] = useState({type:'All Debitor'});
    let [accounts,accountsSet] = useState([]);
    let [locations,locationsSet] = useState([]);
    
    let [reportLoading,reportLoadingSet] = useState(false);
    

    let [selectedAccount,selectedAccountSet] = useState(null)
    let [selectedLocation,selectedLocationSet] = useState(null)
  

    

    
    

    let [print,printSet] = useState(false);
    let [loadingAccounts,loadingAccountsSet] = useState(false);
    let [account_name,account_name_set] = useState('');

    let [balanceTotal,balanceTotalSet] = useState(0)
    
   
    useEffect(()=>{
        selectedAccountSet(null)
        selectedLocationSet(null)
    },[selectedType])



    useEffect(()=>{
          loadingAccountsSet(true)
          axios.post(`${API_URL}/api/get-accounts-by-search`,{query:account_name.trim(),type:'debitor'},{headers:{'auth-token':authInfo.token}}).then(res=>{
            accountsSet(res.data)
            loadingAccountsSet(false)
          })
          
    },[account_name])


    


    const getLocations = async ()=>{
      await axios.post(`${API_URL}/api/get-locations`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        locationsSet(res.data)
    })
    }

 




    
    let getSearchResult = async ()=>{

      if(selectedType == null){
        swal({
          title:'Select Filter Type',
          icon:'warning'
        })
        return false
      }
     
        let url =  `${API_URL}/api/get-sundry-debitor-balance`;

         
            
        let reqPayload = {
            locationId: selectedLocation != null? selectedLocation.location_id:null,
            customerId: selectedAccount != null? selectedAccount.acc_id:null,
        }

        
        reportLoadingSet(true)
        await axios.post(`${url}`,{...reqPayload},{headers:{'auth-token':authInfo.token}}).then(res=>{
             printSet(true)
             reportLoadingSet(false)
            reportSet(res.data.accounts)
            balanceTotalSet(res.data.total_balance)
        })



    }




    let ReportDom = React.forwardRef( (props,ref) =>{
        return(
          <div ref={ref} >
                        <InstitutionProfile />

<div style={{clear:'both'}}></div>
<Paper style={{paddingLeft:'10px',paddingRight:'10px'}} className="print-source">
<p style={{width:'100%',textAlign:'center',fontWeight:'bold'}}> Customer  Due Balance Report</p>
<div style={{float:'left',width:'50%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>

</div>

<div style={{width:'10%'}}></div>

<div style={{float:'right',width:'40%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>

</div>
 


</Paper> 

{
    report.length > 0 ?(<>
    <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}}>
    
    {/* sale Record  without item */} 
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
        <TableHead>
          <TableRow> 
            <TableCell align="left"  width="2%">SL</TableCell>
            <TableCell align="left"  width="4%">Code</TableCell>
            <TableCell align="left"  width="15%">Customer Name </TableCell>
            <TableCell align="left"  width="7%">Contact No</TableCell>
            <TableCell align="left"  width="10%">Address</TableCell>
            <TableCell align="left"  width="5%">Opening </TableCell>
            <TableCell align="left"  width="6%">Bill Amount</TableCell>
            <TableCell align="left"  width="6%">Received </TableCell>
            <TableCell align="right" width="5%">Payment </TableCell>
            <TableCell align="right" width="5%">Return</TableCell>
            <TableCell align="right" width="5%">Discount</TableCell>
            <TableCell align="right" width="7%">Balance</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
                {
                    report.map((acc,ind)=>(
                        <TableRow  key={ind}> 
                        <TableCell  align="left">{ind+parseFloat(1)}</TableCell>
                        <TableCell  align="left">{acc.acc_code}</TableCell>
                        <TableCell  align="left">{acc.acc_name}</TableCell>
                        <TableCell  align="left">{acc.contact_no}</TableCell>
                        <TableCell  align="left">{acc.address}</TableCell>
                        <TableCell  align="right">{format(parseFloat(acc.opening_balance).toFixed(2))}</TableCell>
                        <TableCell  align="right">{format(parseFloat(acc.total_bill_amount).toFixed(2))}</TableCell>
                        <TableCell  align="right">{format(parseFloat(acc.total_received_amount).toFixed(2))}</TableCell>
                        <TableCell  align="right">{format(parseFloat(acc.total_payment).toFixed(2))}</TableCell>
                        <TableCell  align="right">{format(parseFloat(acc.return_amount).toFixed(2))}</TableCell>
                        <TableCell  align="right">{format(parseFloat(acc.discount_amount).toFixed(2))}</TableCell>
                        <TableCell  align="right">{format(parseFloat(acc.balance).toFixed(2))}</TableCell>
                       </TableRow>
                    ))
                }

              

<TableRow>
                <TableCell  style={{fontWeight:'bold'}} align="right" colSpan={5}>Grand Total  : </TableCell>
                <TableCell  style={{fontWeight:'bold'}} align="right">{format(parseFloat(_.sumBy(report,(acc)=>Number(acc.opening_balance))).toFixed(2))}</TableCell>
                <TableCell  style={{fontWeight:'bold'}} align="right">{format(parseFloat(_.sumBy(report,(acc)=>Number(acc.total_bill_amount))).toFixed(2)) }</TableCell>
                <TableCell  style={{fontWeight:'bold'}} align="right">{format(parseFloat(_.sumBy(report,(acc)=>Number(acc.total_received_amount))).toFixed(2))}</TableCell>
                <TableCell  style={{fontWeight:'bold'}} align="right">{format(parseFloat(_.sumBy(report,(acc)=>Number(acc.total_payment))).toFixed(2))}</TableCell>
                <TableCell  style={{fontWeight:'bold'}} align="right">{format(parseFloat(_.sumBy(report,(acc)=>Number(acc.return_amount))).toFixed(2))}</TableCell>
                <TableCell  style={{fontWeight:'bold'}} align="right">{format(parseFloat(_.sumBy(report,(acc)=>Number(acc.discount_amount))).toFixed(2))}</TableCell>
                <TableCell  style={{fontWeight:'bold'}} align="right"> { format(parseFloat(balanceTotal).toFixed(2))}</TableCell>
                </TableRow>
        </TableBody>


 
       
      </Table>
    </TableContainer>
        </Paper>
  
    </>):''
    
}
    


      
     
<div style={{clear: 'both',height:'10px'}}></div>
<div  className="print-source" style={{marginTop:'70px'}}>
                                <p style={{float:'left',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                           
                                 <p style={{float:'right',marginRight:'10px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
                      </div>
          </div>
        )
    })




    const reportRef = useRef();

   

      return(
          <div className={classes.root}> 
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
<h4 style={{ textAlign: 'left',margin: "0px 0px  7px 0px",padding: '0px',color: '#222'}}>Customer Due Balance Report</h4>
<Grid container spacing={3} > 
          <Grid item  xs={12}  sm={2}> 
          <Autocomplete 
          size="small"
          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}}
              options={types} 
              value={selectedType}
              getOptionLabel={(option) => option.type}
              onChange={(event,selectedObj)=>{
                selectedTypeSet(selectedObj) 
              }}
              renderInput={(params) => <TextField 
               
                {...params} 
                label="Filter Type" 
                variant="outlined"
                />} 
          />

          </Grid>

          
         


          <Grid item  xs={12}   sm={3} style={{display: selectedType!=null && (selectedType.type=='Debitor Wise' )?'block':'none'}}> 
          <Autocomplete 
           autoHighlight
           size="small"
             openOnFocus={true}
             style={{width:'100%',height:'20px'}}
             options={accounts}
             loading={loadingAccounts}
             value={selectedAccount}
             getOptionLabel={(option) => option.display_text}
             onChange={(event,selectedObj)=>{
                selectedAccountSet(selectedObj) 
             }}
             renderInput={(params) => <TextField 
              label="Search Customer/ Debtor " 
               onChange={e => account_name_set(e.target.value)} 
               {...params} 
               InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loadingAccounts ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
               variant="outlined"
               />} 
          
      />
          </Grid>


          <Grid item  xs={12}   sm={3} style={{display: selectedType!=null && (selectedType.type=='Location Wise')?'block':'none'}}> 
          <Autocomplete 
          size="small"
          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}} 
              options={locations}
              value={selectedLocation}
              getOptionLabel={(option) => option.location_name}
              onChange={(event,selectedObj)=>{
                 selectedLocationSet(selectedObj);
              }}
              renderInput={(params) => <TextField                
                {...params} 
                label="Select Location" 
                variant="outlined"
                />} 
                
          />
          </Grid>

          <Grid item  xs={12}   sm={2} >
        <Button style={{marginTop: '5px',marginLeft: 'auto',fontSize:'10px'}} 
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<SearchIcon/>}
                            onClick={getSearchResult}
                            disabled={reportLoading?true:false}
                        >
                        GET REPORT 
                      </Button>
        </Grid>
        


          </Grid>


        </Paper>
        {
          print?(
            <Grid container >
            <Grid item xs={12} sm={12} >
              <Paper style={{borderRadius:'0px',marginTop: '-17px'}}>
              <ReactToPrint
                          trigger={() => <PrintIcon  style={{cursor:'pointer',marginLeft: '30px',marginTop: '3px',marginBottom: '-8px'}} />}
                          content={() => reportRef.current}
                        />
              </Paper>
           
            </Grid>
        </Grid>
          ):''
        }
      
            <ReportDom ref={reportRef} /> 




          </div>
      )
}



const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        color: 'white',
        fontSize:'10px',
        backgroundColor: '#115f5f'
      },
    
    root: {
      flexGrow: 1,
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(SalesRecord); 
