import React,{ useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import {acc_types} from '../../account_types.json';
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'


import DateFnsUtils from '@date-io/date-fns';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import moment from 'moment';



import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {pathSpliter,dateTimeFormat,checkIntNum,currentDateTime,getDateTimeFromISODT,dateFormat} from '../../lib/functions'


import {currentRouteSet} from '../../actions/actions';


const GroupManage = ({location,currentRouteSet,currentRoute,authInfo})=>{
    const classes = useStyles();
 
  useEffect(()=>{
    getAccounts();
    getLocations()
    getAccCode()
    getGroups()
    currentRouteSet(pathSpliter(location.pathname,1));
    
  },[])

    // Init states start
 
    
    let [accounts,accountsSet] = useState([])
    let [locations,locationsSet] = useState([])
    let [groups,groupsSet] = useState([])
    let [loadingList,loadingListSet] = useState(false)
    let [loadingSave,loadingSaveSet] = useState(false)
    let [selectedAccountType,selectedAccountTypeSet] = useState(null)
    let [selectedGroup,selectedGroupSet] = useState(null)
    

    let [action,action_set] = useState('create');
    let [acc_id,acc_id_set] = useState(0);
    let [acc_name,acc_name_set] = useState('');
    let [institution_name,institution_name_set] = useState('');
    let [address,address_set] = useState('');
    let [contact_no,contact_no_set] = useState('');
    let [opening_balance,opening_balance_set] = useState(0);
    let [credit_limit,credit_limit_set] = useState(0);
    let [email_address,email_address_set] = useState('');
    let [bank_acc_number,bank_acc_number_set] = useState('');
    let [bank_acc_name,bank_acc_name_set] = useState('');
    let [bank_branch,bank_branch_set] = useState('');
    let [bank_acc_type,bank_acc_type_set] = useState('');
    let [party_type,party_type_set] = useState('no');
    let [acc_code,acc_code_set] = useState('');
    let [contact_person,contact_person_set] = useState('');

    
    let [selectedLocation,selectedLocationSet] = useState(null);
    
    
    const [selectedDate, selectedDateSet] = useState(currentDateTime);

    let [retailer,retailerSet] = useState(false)
    let [wholesaler,wholesalerSet] = useState(false)
    let [distributor,distributorSet] = useState(false)
    let [corporateRate,corporateRateSet] = useState(false)

    const acc_name_ref = useRef(null)
    const acc_type_ref = useRef(null)
    const acc_opening_balance_ref = useRef(null)
    const acc_creation_date_ref = useRef(null)

    const institution_ref = useRef(null)
    const address_ref = useRef(null)
    const contact_ref = useRef(null)
    const location_ref = useRef(null)
    const email_ref = useRef(null)
    const credit_limit_ref = useRef(null)

    const bank_acc_name_ref = useRef(null)
    const bank_acc_number_ref = useRef(null)
    const bank_acc_branch_ref = useRef(null)
    const bank_acc_type_ref = useRef(null)
    const group_ref = useRef(null)
    


    const handleDateChange = (date) => {
        selectedDateSet(date);
      };

    // Init states end
    // Methods script start
    const saveFormAction = async ()=>{
          if(acc_name.trim() == ''){
            swal({
              title:'Account Name is Required.',
              icon:'warning'
            })
          }else if(selectedAccountType == null){
            swal({
              title:'Select Account Type',
              icon:'warning'
            })
          }else{
            loadingSaveSet(true);

            await axios.post(`${API_URL}/api/save-account`,{
              acc_code:acc_code.trim(),
              acc_name:acc_name.trim(),
              acc_type:selectedAccountType,
              opening_balance:opening_balance =='' ? 0 : opening_balance,
              institution_name,
              address,
              contact_no,
              party_type,
              creation_date:selectedDate,
              location_id:selectedLocation != null ? selectedLocation.location_id: 0,
              group_id:selectedGroup != null ? selectedGroup.group_id: 0,
              credit_limit: credit_limit ==''?0:credit_limit,
              email_address,
              bank_acc_number,
              bank_acc_name,
              bank_branch,
              bank_acc_type,
              action,
              acc_id
            },{headers:{'auth-token': authInfo.token}}).then(res=>{
              loadingSaveSet(false);
              getAccounts()
              if(res.data.error){
                swal({
                  title: res.data.msg,
                  icon:'warning'
                })
              }else{
                swal({
                  title: res.data.msg,
                  icon:'success'
                })

                getAccCode()

                acc_id_set(0)
                acc_name_set('')
                action_set('create')
                selectedAccountTypeSet(null)
                opening_balance_set(0)
                institution_name_set('')
                address_set('')
                contact_no_set('')
                party_type_set('no')
                selectedDateSet(currentDateTime)
                selectedLocationSet(null)
                credit_limit_set(0)
                email_address_set('')
                bank_acc_number_set('')
                bank_acc_name_set('')
                bank_branch_set('')
                bank_acc_type_set('')

                selectedGroupSet(null)

                retailerSet(false)
                wholesalerSet(false)
                distributorSet(false)
                corporateRateSet(false)



              }
              
            });
          }
          
    }

    let [defaults,defaultsSet] = useState(['Discount on purchase','discount on service','Vat & Tax Account','Discount on sale',
                                'Sales','Sales Return','Discount on service','Purchase','Purchase Return','Salary','Services','Service Expense'])


  


  const getAccCode = async ()=>{
    await axios.post(`${API_URL}/api/get-acc-code`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        acc_code_set(res.data)

      
    })
  }

                                

  
    const getAccounts = async ()=>{
      await axios.post(`${API_URL}/api/get-accounts`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        accountsSet(res.data)
      })
    }

    const getLocations = async ()=>{
      await axios.post(`${API_URL}/api/get-locations`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        locationsSet(res.data)
      })
    }

    const getGroups = async ()=>{
      await axios.post(`${API_URL}/api/get-collection-groups`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        groupsSet(res.data)
      })
    }





    // Methods script end
    const ActionOptions = (props)=>{
      return(<div style={{textAlign:'right'}}> 

{
    authInfo.role =='super_admin'?(
    <>
        <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>accountEdit(props.rowData)}/>
      {
        props.rowData[1] == 'debitor' || props.rowData[1] == 'creditor' || props.rowData[1] == 'indirect_expense'  || props.rowData[1] == 'bank_account' ||  props.rowData[1] == 'capital' ||   props.rowData[1] == 'fixed_asset' ||   props.rowData[1] == 'loan'  ||   props.rowData[1] == 'investment' && (props.rowData[2] != 'general')?<>
               <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>accountDelete(props.rowData)}/>
        </>:''
      }
            
   </>):''
 }
      
      </div>)
    
    }


    const accountEdit = (row,index)=>{
      let account =  accounts.filter((account)=>account.acc_id == row[0])

 
      let result =   defaults.findIndex((acc)=>{
        let real = acc.trim().toLowerCase()
        let check = account[0].acc_name.trim().toLowerCase()
          return real == check
        })

     if(result > -1){
      swal({
        title: `Default Account  Can't be Edit. `,
        icon:'warning'
      });
      return false
     }


      acc_id_set(account[0].acc_id)
      acc_name_set(account[0].acc_name)
      action_set('update')

      selectedGroupSet({group_id: account[0].group_id,group_name:  account[0].group_name})

      selectedAccountTypeSet({acc_type_name:account[0].acc_type_name,acc_type_id:account[0].acc_type_id,label:account[0].acc_type_name})
      opening_balance_set(account[0].opening_balance)
      institution_name_set(account[0].institution_name)
      address_set(account[0].address)
      contact_no_set(account[0].contact_no)

      if(account[0].acc_code != null || account[0].acc_code ==''){
        acc_code_set(account[0].acc_code)
      }else{
        acc_code_set('')

      }
      party_type_set(account[0].party_type)
      selectedDateSet(account[0].creation_date)
      selectedLocationSet({location_name:account[0].location_name,location_id:account[0].location_id})
      credit_limit_set(account[0].credit_limit)
      email_address_set(account[0].email_address)
      bank_acc_number_set(account[0].bank_acc_number)
      bank_acc_name_set(account[0].bank_acc_name)
      bank_branch_set(account[0].bank_branch)
      bank_acc_type_set(account[0].bank_acc_type)

      if(account[0].party_type=='retailer'){
        retailerSet(true)
      }else{
        retailerSet(false)
      }

      if(account[0].party_type=='wholesaler'){
        wholesalerSet(true)
      }else{
        wholesalerSet(false)
      }

      if(account[0].party_type=='distributor'){
        distributorSet(true)
      }else{
        distributorSet(false)
      }

      if(account[0].party_type=='corporate'){
        corporateRateSet(true)
      }else{
        corporateRateSet(false)
      }
      


    }


    const accountDelete = async (row)=>{

      let account =  accounts.filter((account)=>account.acc_id == row[0])


      swal({
        title:'Are you sure delete this?',
        icon:'warning',
        buttons:true
      }).then(async(yes)=>{

        

      let result =   defaults.findIndex((acc)=>{
        let real = acc.trim().toLowerCase()
        let check = account[0].acc_name.trim().toLowerCase()
          return real == check
        })

     if(result > -1){
      swal({
        title: `This Account  Can't be Deleted.`,
        icon:'warning'
      });
      return false
     }
    
        if(yes){
          await axios.post(`${API_URL}/api/delete-account`,{acc_id:account[0].acc_id},
          {headers:{'auth-token':authInfo.token}}).then(res=>{
                  getAccounts();
                  swal({
                    title: res.data.msg,
                    icon:'success'
                  })
                  
                })
        }else{
          return false
        }
      })
      

          }
   
    const columns = [
      {name: "acc_id",options: { display: 'excluded' }},
      {name: "acc_type_id",options: { display: 'excluded' }},
      {name: "party_type",options: { display: 'excluded' }},
      {name: "acc_code",label: "Code",options: {filter: true,sort: true}},
      {name: "acc_name",label: "Account Name",options: {filter: true,sort: true}},
      {name: "acc_type_name",label: "Account Type",options: {filter: true,sort: true}},
      {name: "opening_balance",label: "Opening Balance",options: {filter: true,sort: true}},
      {name: "creation_date",label: "Entry Date",options: {filter: true,sort: true,
        customBodyRender:(value,tableMeta)=>{
          return(<p>{ tableMeta.rowData[7] != null && tableMeta.rowData[7].trim() != '' ?  moment(tableMeta.rowData[7]).format(dateFormat) : '' }</p>) 
        }
      }
      
    },

      
      {name:"actions",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
          return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
             /> ); 
      }
      },headerStyle: {
        textAlign:'right'
      }}
     ];
            
     const options = {
       filterType: 'checkbox',
       selectableRows: 'none',
       display: "excluded"
      }




    return (
    <div className={classes.root}>

     <Paper className={classes.paper} style={{marginTop:'-15px'}}>
    <h2 className={classes.pageEntryLabel}>Account Entry </h2>
      <Grid container spacing={2}>


      <Grid item xs={12} sm={2}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
          // inputRef={acc_name_ref}
          // onKeyDown={event => {
          //   if (event.key === "Enter") {
          //     acc_type_ref.current.focus()
          //   }
          // }}
          type="text"
        label="Account Code" name="acc_code" value={acc_code} variant="outlined" size="small" onChange={(e)=>acc_code_set(e.target.value)} />
        </Grid>



      
      
        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
          inputRef={acc_name_ref}
          onKeyDown={event => {
            if (event.key === "Enter") {
              acc_type_ref.current.focus()
            }
          }}

          disabled={
            acc_name == 'Cash' ? true : false
          }
        label="Account Name" name="acc_name" value={acc_name} variant="outlined" size="small" onChange={(e)=>acc_name_set(e.target.value)} />
        </Grid>


        <Grid item xs={12} sm={2}> 
              <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}

             

              options={acc_types} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.label}
              value={selectedAccountType}
              onChange={(event,selectedObj)=>{
                selectedAccountTypeSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                inputRef={acc_type_ref}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    acc_opening_balance_ref.current.focus()
                  }
                }}
                  {...params}
                  label="Account Type"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>
        <Grid item xs={12} sm={2}> 
        <TextField type="number"  autoComplete='off' className={classes.fullWidth} 
          inputRef={acc_opening_balance_ref}
          onKeyDown={event => {
            if (event.key === "Enter") {
              acc_creation_date_ref.current.focus()
            }
          }}
        label="Opening Balance" name="opening_balance" value={opening_balance} variant="outlined" size="small" onChange={(e)=>opening_balance_set(e.target.value)} />
        </Grid>

       

       {
         selectedAccountType != null && (selectedAccountType.acc_type_id == 'debitor' || selectedAccountType.acc_type_id == 'creditor') ? (<>
           <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' 
         inputRef={institution_ref}
         onKeyDown={event => {
           if (event.key === "Enter") {
            address_ref.current.focus()
           }
         }}
          className={classes.fullWidth} 
        label="Institution Name" name="institution_name" value={institution_name} variant="outlined" size="small" onChange={(e)=>institution_name_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' 
         inputRef={address_ref}
         onKeyDown={event => {
           if (event.key === "Enter") {
            contact_ref.current.focus()
           }
         }}
          className={classes.fullWidth} 
        label="Address" name="address" value={address} variant="outlined" size="small" onChange={(e)=>address_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' 
         inputRef={contact_ref}
         onKeyDown={event => {
           if (event.key === "Enter") {
            location_ref.current.focus()
           }
         }}
          className={classes.fullWidth} 
        label="Contact No" name="contact_no" value={contact_no} variant="outlined" size="small" onChange={(e)=>contact_no_set(e.target.value)} />
        </Grid>

      

        <Grid item xs={12} sm={3} className={classes.plusLinkDiv}> 
        <Link to="/accounts/location-manage" className={classes.plusLink} style={{cursor:'pointer'}}  >+</Link>  

              <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={locations} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.location_name}
              value={selectedLocation}
              onChange={(event,selectedObj)=>{
                selectedLocationSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                inputRef={location_ref}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    email_ref.current.focus()
                  }
                }}
                  {...params}
                  label="Location"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>

       


        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
          inputRef={email_ref}
          onKeyDown={event => {
            if (event.key === "Enter") {
              credit_limit_ref.current.focus()
            }
          }}
        label="E-mail Address" name="email_address" value={email_address} variant="outlined" size="small" onChange={(e)=>email_address_set(e.target.value)} />
        </Grid>


         </>):''
       }

        {
          selectedAccountType != null && (selectedAccountType.acc_type_id == 'debitor')?(<>

            <Grid item xs={12} sm={3}> 
            <TextField type="number"  autoComplete='off' className={classes.fullWidth} 
            inputRef={credit_limit_ref}
           
            label="Credit Limit" name="credit_limit" value={credit_limit} variant="outlined" size="small" onChange={(e)=>credit_limit_set(e.target.value)} />
            </Grid>



            <Grid item xs={12} sm={3}> 
            <TextField type="text"  autoComplete='off' className={classes.fullWidth} 
           
            label="Contact Person" name="contact_person" value={contact_person} variant="outlined" size="small" onChange={(e)=>contact_person_set(e.target.value)} />
            </Grid>


           
           <Grid item xs={12} sm={6} style={{textAlign: 'left'}}>
            <FormControl component="fieldset" > 
            <FormLabel component="legend" style={{textAlign:'left'}}>Party Type</FormLabel>
            <RadioGroup row aria-label="party_type" name="position"   defaultValue={party_type} onChange={(e)=>{
              if(e.target.value=='retailer'){
                retailerSet(true)
              }else{
                retailerSet(false)
              }

              if(e.target.value=='wholesaler'){
                wholesalerSet(true)
              }else{
                wholesalerSet(false)
              }

              if(e.target.value=='distributor'){
                distributorSet(true)
              }else{
                distributorSet(false)
              }

              if(e.target.value=='corporate'){
                corporateRateSet(true)
              }else{
                corporateRateSet(false)
              }
              


              party_type_set(e.target.value)
              }} >
              <FormControlLabel value="retailer" control={<Radio color="primary"  />} checked={retailer} label="Retailer " />
              <FormControlLabel value="wholesaler" control={<Radio color="primary" checked={wholesaler} />}  label="WholeSaler " />
              <FormControlLabel value="distributor" control={<Radio color="primary" checked={distributor} />}  label="Distributor " />
              <FormControlLabel value="corporate" control={<Radio color="primary" checked={corporateRate} />}  label="Corporate " />
            </RadioGroup>
            </FormControl>
            </Grid>



            <Grid item xs={12} sm={3} className={classes.plusLinkDiv}> 

        <Link to="/accounts/collection-group-entry" className={classes.plusLink} style={{cursor:'pointer'}}  >+</Link>  

              <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={groups} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.group_name}
              value={selectedGroup}
              onChange={(event,selectedObj)=>{
                selectedGroupSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                inputRef={group_ref}
              
                  {...params}
                  label="Collection Group"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>


          
          </>):''
        }

        



       {
         selectedAccountType != null && (selectedAccountType.acc_type_id == 'bank_account')?(<>
           <Grid item xs={12} sm={3}> 
            <TextField  autoComplete='off' className={classes.fullWidth} 
              inputRef={bank_acc_name_ref}
              onKeyDown={event => {
                if (event.key === "Enter") {
                  bank_acc_number_ref.current.focus()
                }
              }}
            label="Bank Account Name" name="bank_acc_name" value={bank_acc_name} variant="outlined" size="small" onChange={(e)=>bank_acc_name_set(e.target.value)} />
            </Grid>

            <Grid item xs={12} sm={3}> 
            <TextField  autoComplete='off' className={classes.fullWidth} 
             inputRef={bank_acc_number_ref}
             onKeyDown={event => {
               if (event.key === "Enter") {
                bank_acc_branch_ref.current.focus()
               }
             }}
            label="Bank Account Number" name="bank_acc_number" value={bank_acc_number} variant="outlined" size="small" onChange={(e)=>bank_acc_number_set(e.target.value)} />
            </Grid>

            <Grid item xs={12} sm={3}> 
            <TextField  autoComplete='off' className={classes.fullWidth} 
              inputRef={bank_acc_branch_ref}
              onKeyDown={event => {
                if (event.key === "Enter") {
                  bank_acc_type_ref.current.focus()
                }
              }}
            label="Bank Account Branch" name="bank_branch" value={bank_branch} variant="outlined" size="small" onChange={(e)=>bank_branch_set(e.target.value)} />
            </Grid>

            <Grid item xs={12} sm={3}> 
            <TextField  autoComplete='off' className={classes.fullWidth} 
            inputRef={bank_acc_type_ref}
            label="Bank Account Type" name="bank_acc_type" value={bank_acc_type} variant="outlined" size="small" onChange={(e)=>bank_acc_type_set(e.target.value)} />
            </Grid>

         </>):''
       }

        



       
          

<Grid item xs={12} sm={3} style={{marginTop:'-20px'}}> 
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
       
        <KeyboardDatePicker
          margin="normal"
          label="Creation Date"
          format="MM/dd/yyyy"
          inputRef={acc_creation_date_ref}

          onKeyDown={event => {
            if (event.key === "Enter") {
              if(selectedAccountType != null & (selectedAccountType.acc_type_id=='creditor' || selectedAccountType.acc_type_id=='debitor')){
                institution_ref.current.focus()
              }else if(selectedAccountType != null & (selectedAccountType.acc_type_id=='bank_account')){
                bank_acc_name_ref.current.focus()
              }else{
                saveFormAction()
              }
             
            }
          }}
         
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        
    
    </MuiPickersUtilsProvider>
        </Grid>


  
  </Grid>


  
         <Grid item xs={12}>
  <Button style={{marginTop: '25px'}}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        disabled={loadingSave}
        startIcon={<SaveIcon />}
        onClick={saveFormAction}
      >
        Save
      </Button>
  </Grid>         


     </Paper>

     {
      loadingList==true?(<b>Loading...</b>):(
        <Paper className={classes.paper} style={{marginTop:'20px'}}>
        <MUIDataTable
      title={"Account List"}
      data={accounts}
      columns={columns}
      options={options}
      adjustForCheckbox={false} 
      displaySelectAll={false}
      />
      </Paper>
      )
      
     }

     
    </div>
        
    );
}


 const options = {
   filterType: 'checkbox',
 };
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },

root: {},
'& .MuiTextField-root': {
  margin: theme.spacing(1),
  width: '25ch',
 },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  },
  pageEntryLabel:{
      color: '#7754cc',
      margin: '0px',
      padding: '0px',
      marginTop: '-11px',
      textAlign: 'left',
      marginLeft: '0px',
      marginBottom: '5px'
  },
  fullWidth:{
      width:'100%'
  },
  option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    plusLinkDiv:{
      position:'relative'  
},
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    }
}));

const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(GroupManage);